import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";
import styled from "@emotion/styled";

const LinkTag = styled(Link)`
  text-decoration: none;
  color: rgba(117, 117, 117, 1);
  :hover {
    text-decoration: underline;
  }
`;
const WrapperContent = styled.div`
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0em 0em 0.6em;
`;

const Categories = (props) => {
  const { categories, tag, style } = props;
  return (
    <WrapperContent className="wrapper-category" style={{ ...style }}>
      {tag && (
        <div
          style={{
            color: "rgba(117, 117, 117, 1)",
            marginRight: "0.5em",
          }}
        >
          TAGS:
        </div>
      )}

      {categories.map((cat, i) => (
        <React.Fragment key={i}>
          {cat.category !== null && (
            <>
              {!!i && ", "}
              <LinkTag
                to={`/blog/${kebabCase(
                  cat.category.document?.data.name || cat.category.name
                )}`}
              >
                {cat.category.document?.data.name || cat.category.name}
              </LinkTag>
            </>
          )}
        </React.Fragment>
      ))}
    </WrapperContent>
  );
};

export default Categories;
